<template>
  <div class="search">
    <div class="search-all">
      <div class="search-box flex-between">
        <div class="left" @click="$router.go(-1)"></div>
        <div class="center ellipsis">审核规则</div>
        <div class="right"></div>
      </div>
      <div class="section">
        <div class="title">
          <span class="tip"></span>色区审核规则
        </div>
        <div class="sec">
          <p>1.少于3张的图片不通过审核；<br/>
          2.内容不包含有漏点、美感、诱惑、成人主题相关的则不通过审核；<br/>
          3.内容带有幼女、人兽、血腥、暴力、政治敏感的不通过审核；<br/>
          4.内容模糊不清不通过审核；<br/>
          5.内容为单男的不通过审核；<br/>
          6.作品带有非官方水印、或者其他有损官方利益的信息不通过审核；<br/>
          7.内容或标题带联系方式不通过审核；<br/>
          8.内容平淡无奇，吸引力不住不通过审核；<br/>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return{
    }
  },
  methods:{
  }
}
</script>
<style lang="scss" scoped>
.search-all{
  height:100%;
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
}
.search-box{
  padding:0 16px;
  height:52px;
  align-items: center;
  background:$vermillion;
}
.left{
  width:22px;
  height:22px;
  background:url('../../assets/png/back_icon.png') center center no-repeat;
  background-size:100%;
}
.center{
  font-size:20px;
  font-weight: bold;
  color:#ffffff;
}
.right{
  width:22px;
}
.section{
  height: calc(100% - 52px);
  overflow-y:auto;
  -webkit-overflow-scrolling: touch;
  padding:10px 10px 30px;
  box-sizing: border-box;
}
.title{
  font-size:16px;
}
.tip{
  display: inline-block;
  width:6px;
  height:18px;
  border-radius: 2px;
  background:$vermillion;
  margin-right:15px;
  margin-top:10px;
  vertical-align:-3px;
}
.sec{
  margin-top:14px;
  color:#515151;
  font-size:13px;
}
.sec>p{
  line-height: 18px;
}
</style>
